import './styles/style.scss';
import configQuery from 'Config/ConfigPerUrlQuery.graphql';
import { layouts } from './layouts';

const client = require('@carwow/polaris-coreweb/dist/client.bundle');

client.default({
  layouts,
  // the credentials are injected into the global state in core.
  credentials: window.__API_CREDENTIALS__,
  // Apollo state is injected into the global state in core.
  state: window.__APOLLO_STATE__,
  schemaFragment: window.__APOLLO_SCHEMA_FRAGMENTS__,
  root: document.getElementById('root'),
  configQuery,
  cookie: document.cookie,
});
