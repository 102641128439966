import get from 'lodash.get';
import { Helmet, imageSizesCallback } from 'polaris-coreweb/exports';
import { getFooterConfig } from './getFooterConfig';
import { getHeaderConfig } from './getHeaderConfig';

// further info in withTemplate.md
export const contextOverride = (config, adConfig) => ({
  adConfig,
  branding: get(config, 'branding', {}),
  breakpoints: get(config, 'breakpoints', {}),
  carwow: get(config, 'carwow', {}),
  siteUrl: get(config, 'siteUrl', ''),
  footer: getFooterConfig(config),
  globalSettings: get(config, 'globalSettings', {}),
  gycConfig: get(config, 'gycConfig', {}),
  header: getHeaderConfig(config),
  HelmetComponent: Helmet,
  helpCentre: get(config, 'helpCentre', {}),
  imageSizesCallback: imageSizesCallback(config),
  newsletter: get(config, 'newsletterConfig', {}),
  newsletterURL: get(config, 'newsletter.newsletterURL', null),
  platform: 'canonical',
  productSelector: get(config, 'productSelector', {
    popularInputs: get(config, 'productSelector.popularInputs', []),
    popularInputsLabel: get(config, 'productSelector.popularInputsLabel', ''),
  }),
  promoBoxSettings: get(config, 'globalSettings.promoBoxSettings', []),
  ratingConfig: {
    iconPath: get(config, 'rating.icon.iconPath', 'M5.24 22.4L12 17.49l6.76 4.91-2.58-7.94 6.75-4.92h-8.35L12 1.6 9.42 9.54H1.06l6.76 4.92z'),
    iconViewBox: get(config, 'rating.icon.iconViewBox', '0 0 24 24'),
  },
  ratingFillColor: get(config, 'rating.color.ratingFillColor', {}),
  ratingFillInactiveColor: get(config, 'rating.color.ratingFillInactiveColor', {}),
  reportCardRatingConfig: get(config, 'reportCardRatingConfig', {}),
  howWeReviewUrl: get(config, 'howWeReviewUrl', ''),
  regPlateFormConfig: get(config, 'regPlateFormConfig', {}),
  login: get(config, 'login', {}),
  svgSpritePath: get(config, 'svgSpritePath', '/assets/polaris-sprite.svg'),
  social: get(config, 'social', {}),
  testing: true,
});
