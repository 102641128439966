import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { asyncTrackLinkClick } from 'Helpers/tracking';

import './_style.scss';

/**
 * Handles the link click event by tracking the click asynchronously.
 *
 * @param {Object} params - The parameters for tracking.
 * @param {string} params.label - The label of the button.
 * @param {string} params.partner - The partner associated with the button.
 */
const handleLinkClick = async ({ label, partner }) => {
  await asyncTrackLinkClick({
    event: 'buybox.button_click',
    'buybox.button': label,
    'buybox.partner': partner,
  });
};

/**
 * StickyBuyBox component renders a sticky buy box with clickable links.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.context - The context object.
 * @param {string} props.context.cssPrefix - The CSS prefix for styling.
 * @param {Array} props.items - The array of items to display in the buy box.
 *
 * @returns {JSX.Element} The rendered StickyBuyBox component.
 */
const StickyBuyBox = ({
  context: {
    cssPrefix,
  },
  items,
}) => {
  const [bottomDistance, setBottomDistance] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const adElement = document.querySelector('.polaris__ad.-mobile-sticky');
    const reportCardElement = document.querySelector('.polaris__report-card');

    const updateBottomDistance = () => {
      if (adElement) {
        if (adElement.classList.contains('-empty-ad-slot')) {
          setBottomDistance(0);
        } else {
          const adHeight = adElement.offsetHeight;
          setBottomDistance(adHeight);
        }
      }
    };

    updateBottomDistance();

    const observer = new MutationObserver(updateBottomDistance);
    if (adElement) {
      observer.observe(adElement, { attributes: true, childList: true, subtree: true });
    }

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.boundingClientRect.top > 0) {
          setIsVisible(false);
        } else if (entry.intersectionRatio > 0.5) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      });
    };

    const intersectionObserver = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0.5,
    });

    if (reportCardElement) {
      intersectionObserver.observe(reportCardElement);
    }

    return () => {
      if (adElement) {
        observer.disconnect();
      }
      if (reportCardElement) {
        intersectionObserver.unobserve(reportCardElement);
      }
    };
  }, []);

  return (
    <div
      className={`${cssPrefix}__sticky-buybox`}
      style={{ bottom: `${bottomDistance}px`, display: isVisible ? 'flex' : 'none' }}
    >
      {items.map((item, index) => (
        <Link
          key={index}
          href={item.ctaUrl}
          extraClassNames={{
            [`${cssPrefix}__sticky-buybox-item`]: true,
            '-link-as-button': true,
          }}
          onClick={async () => {
            await handleLinkClick({
              label: item.ctaGA4Label,
              partner: item.ctaGA4Partner,
            });
          }}
        >
          {item.shortCtaText}
        </Link>
      ))}
    </div>
  );
};

StickyBuyBox.propTypes = {
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ctaGA4Label: PropTypes.string,
      ctaGA4Partner: PropTypes.string,
      ctaUrl: PropTypes.string,
      shortCtaText: PropTypes.string,
    }),
  ),
};

StickyBuyBox.defaultProps = {
  context: {},
  items: [],
};

export default withPolaris(StickyBuyBox);
